import { render, staticRenderFns } from "./IconEmployees.vue?vue&type=template&id=dab7346a&lang=pug&functional=true&"
import script from "./IconEmployees.vue?vue&type=script&lang=js&"
export * from "./IconEmployees.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports